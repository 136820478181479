/* ============
 * vue-svgicon
 * ============
 *
 * A tool to create svg icon components. https://github.com/MMF-FE/vue-svgicon
 *
 * ============
 * Use the template [svg-icon-template] to set svg properties when running npm svg run.
 * Uncomment to use it on npm run svg and comment it again to run server or it will cause error
 * Specify the path for the template in package.json script ===> --tpl ./src/plugins/svg-icon-template.js
 * ============
 */
import Vue from 'vue'
// https://vcalendar.io/
import VCalendar from 'v-calendar'

// Use v-calendar & v-date-picker components
Vue.use(VCalendar)
