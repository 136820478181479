/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'share-filled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M18.78 15.542a3.704 3.704 0 00-3.146 1.73L9.191 13.36a3.703 3.703 0 000-2.754l6.445-3.882a3.725 3.725 0 003.144 1.733 3.733 3.733 0 003.728-3.73A3.733 3.733 0 0018.78 1a3.733 3.733 0 00-3.73 3.729c0 .468.091.915.25 1.328L8.846 9.944a3.727 3.727 0 00-3.117-1.69A3.733 3.733 0 002 11.985a3.733 3.733 0 003.729 3.728c1.303 0 2.45-.673 3.117-1.688l6.452 3.916A3.724 3.724 0 0018.78 23c.996 0 1.932-.388 2.636-1.092a3.704 3.704 0 001.092-2.637 3.7 3.7 0 00-1.092-2.636 3.704 3.704 0 00-2.636-1.093z" id="svgicon_share-filled_a"/></defs><use xlink:href="#svgicon_share-filled_a"/>'
  }
})
