/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'note': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M22.831 2.448l-.009-.03a.545.545 0 00-.027-.082l-.02-.035a.518.518 0 00-.04-.065c-.008-.012-.018-.023-.027-.034a.528.528 0 00-.051-.054l-.014-.014c-.006-.006-.014-.01-.02-.014a.575.575 0 00-.17-.09c-.013-.004-.027-.009-.041-.012a.535.535 0 00-.073-.012c-.015-.002-.03-.004-.046-.004L22.277 2c-.02 0-.04.004-.059.006-.012.001-.024 0-.036.002L8.779 4.303l-.01.002-.043.012c-.02.006-.04.01-.059.018l-.041.02a.59.59 0 00-.135.092l-.031.03a.497.497 0 00-.064.083.592.592 0 00-.029.054l-.019.039a.618.618 0 00-.02.065l-.01.036a.554.554 0 00-.01.108V17.61A3.705 3.705 0 005.72 16.56 3.725 3.725 0 002 20.279C2 22.332 3.67 24 5.72 24c2.053 0 3.722-1.67 3.722-3.72V10.143l12.268-2.1v7.273a3.705 3.705 0 00-2.586-1.052 3.725 3.725 0 00-3.721 3.721c0 2.052 1.669 3.721 3.72 3.721 2.052 0 3.721-1.67 3.721-3.72V2.566a.582.582 0 00-.013-.12z" id="svgicon_note_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_note_b" _fill="#fff"><use xlink:href="#svgicon_note_a"/></mask><use _fill="#231F20" fill-rule="nonzero" xlink:href="#svgicon_note_a"/><g mask="url(#svgicon_note_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
