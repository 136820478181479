import Vue from 'vue'
import Vuex from 'vuex'

// modules
import base from '#/store/base'
import auth from '~/store/auth'
import beat from '#/store/beat'
import user from '#/store/user'
import orders from '#/store/orders'
import upload from '~/store/upload'
import modal from '~/store/modal'

// caches
import { vuexToken } from '~/store/vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    // plugins: [vuexMain, vuexUser, vuexToken, vuexLicenses, vuexAnalytics],
    plugins: [vuexToken],
    modules: { base, auth, beat, user, orders, upload, modal }
})
