/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'wallet': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M19.333 8H5c-1.667 0-3 1.2-3 2.667v8.266C2 20.533 3.4 22 5 22h14c1.6 0 3-1.4 3-3v-1h-3.667c-1.6 0-3-1.4-3-3s1.4-3 3-3H22v-1.333c0-1.4-1.133-2.534-2.667-2.667zM16 15c0 1.2 1.133 2.333 2.333 2.333H22v-4.666h-3.667C17.133 12.667 16 13.8 16 15zm3.333 0c0 .533-.466 1-1 1-.533 0-1-.467-1-1 0-.533.467-1 1-1 .534 0 1 .4 1 1zm-8.066-7.667H5c-.533 0-1 .067-1.467.267.267-.267.6-.467.934-.533L15.133 2.4c1-.467 2.067-.467 2.867.067C18.8 3 19.267 3.933 19.267 5v2.333H13L16.4 6c.2-.133.267-.267.2-.467s-.2-.266-.4-.2l-4.933 2z" id="svgicon_wallet_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_wallet_a"/>'
  }
})
