/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'plug': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M21.917 2.1a.322.322 0 00-.466 0l-3.195 3.195-.2-.2c-.399-.4-.998-.466-1.464-.133l-1.664 1.065c-.665-.4-1.464-.666-2.262-.666a4.273 4.273 0 00-3.062 1.265L7.607 8.689l-.266-.133c-.2-.266-.532-.333-.799-.333-.266 0-.665.067-.865.333l-1.33 1.33a1.165 1.165 0 000 1.665l.93.932-2.928 2.861a1.165 1.165 0 000 1.664c.267.2.533.333.866.333.332 0 .599-.133.798-.333l2.929-2.928 2.995 3.061-2.929 2.862a1.165 1.165 0 000 1.664c.267.2.533.333.799.333s.599-.133.799-.333l2.862-2.862.865.866c.2.2.532.332.798.332.267 0 .6-.133.8-.332l1.33-1.332a1.165 1.165 0 000-1.663l-.466-.466 2.13-2.064a4.39 4.39 0 001.265-3.061c0-.666-.133-1.265-.4-1.864l1.198-1.597c.333-.466.267-1.065-.133-1.464l-.2-.333 3.195-3.195c.133-.133.133-.4.067-.532zm-7.255 8.253c0 .532-.466.998-.998.998-.533 0-.998-.466-.998-.998 0-.533.465-.999.998-.999.532 0 .998.466.998.999z" id="svgicon_plug_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_plug_a"/>'
  }
})
