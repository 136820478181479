/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cloud-dl': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M18.59 7.9c2.52.28 4.41 2.38 4.41 4.9 0 2.73-2.17 4.9-4.9 4.9h-.35c-.21 0-.35-.14-.35-.35 0-2.87-2.38-5.25-5.25-5.25S6.9 14.48 6.9 17.35c0 .21-.14.35-.35.35h-.7C3.75 17.7 2 15.95 2 13.85c0-2.03 1.54-3.71 3.5-3.85v-.35C5.5 6.01 8.51 3 12.15 3c3.08 0 5.67 1.96 6.44 4.9zm-6.44 14a4.54 4.54 0 01-4.55-4.55 4.54 4.54 0 014.55-4.55 4.54 4.54 0 014.55 4.55 4.54 4.54 0 01-4.55 4.55zm-1.68-3.43c-.07.14-.07.42-.07.56l1.4 1.4c.07 0 .07.07.14.07.07.07.21.07.28 0 .07 0 .14-.07.14-.07l1.4-1.4c.14-.14.14-.35 0-.49a.338.338 0 00-.49 0l-.77.77v-4.76c0-.21-.14-.35-.35-.35-.21 0-.35.14-.35.35v4.69l-.77-.77c-.07-.07-.14-.07-.28-.07s-.21 0-.28.07z" id="svgicon_cloud-dl_a"/></defs><use _fill="#941AFF" xlink:href="#svgicon_cloud-dl_a"/>'
  }
})
