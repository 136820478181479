/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'trash': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M18.333 7.333H5c-.2 0-.333.134-.333.334v11.466C4.667 20.733 6 22 7.6 22h8.133c1.6 0 2.934-1.267 2.934-2.867V7.667c0-.2-.134-.334-.334-.334zM8 19c0 .2-.133.333-.333.333S7.333 19.2 7.333 19V9c0-.2.134-.333.334-.333S8 8.8 8 9v10zm2.667 0c0 .2-.134.333-.334.333S10 19.2 10 19V9c0-.2.133-.333.333-.333s.334.133.334.333v10zm2.666 0c0 .2-.133.333-.333.333s-.333-.133-.333-.333V9c0-.2.133-.333.333-.333s.333.133.333.333v10zM16 19c0 .2-.133.333-.333.333s-.334-.133-.334-.333V9c0-.2.134-.333.334-.333S16 8.8 16 9v10zm1-15.667c1.267 0 2.333 1.067 2.333 2.334v.666c0 .2-.133.334-.333.334H4.333c-.2 0-.333-.134-.333-.334v-.666C4 4.4 5.067 3.333 6.333 3.333h1.8l.667-.666C9.267 2.267 9.867 2 10.467 2H12.8c.6 0 1.2.2 1.667.667l.666.666H17z" id="svgicon_trash_a"/></defs><use xlink:href="#svgicon_trash_a"/>'
  }
})
