/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'add': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12 1C5.935 1 1 5.934 1 12s4.935 11 11 11 11-4.934 11-11S18.065 1 12 1zm0 21.154C6.401 22.154 1.846 17.599 1.846 12S6.401 1.846 12 1.846 22.154 6.401 22.154 12 17.599 22.154 12 22.154zm5.288-10.577h-4.865V6.923a.423.423 0 10-.846 0v4.654H6.712a.423.423 0 100 .846h4.865V17.5a.423.423 0 10.846 0v-5.077h4.865a.423.423 0 100-.846z" id="svgicon_add_a"/></defs><use _fill="#424242" xlink:href="#svgicon_add_a"/>'
  }
})
