/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'money-bag': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M15.147 7.6c1.613 1.687 5.72 6.233 5.72 8.433 0 3.814-3.814 6.967-8.434 6.967C7.813 23 4 19.847 4 16.033c0-2.2 4.107-6.746 5.72-8.433zm-2.714 4.473c-.22 0-.366.147-.366.367v.367c-.807.146-1.467.88-1.467 1.686 0 .807.513 1.394 1.467 1.687v2.053c-.44-.146-.734-.44-.734-.88 0-.22-.146-.366-.366-.366s-.367.146-.367.366c.073.807.66 1.467 1.467 1.614l.006.079c.031.175.168.287.36.287.22 0 .367-.146.367-.366l.15-.047c.737-.257 1.317-.888 1.317-1.713 0-.807-.514-1.394-1.467-1.687v-2.053c.44.146.733.44.733.88 0 .22.147.366.367.366s.367-.146.367-.366a1.791 1.791 0 00-1.467-1.614v-.293c0-.22-.147-.367-.367-.367zm.367 4.254c.367.146.733.366.733.88 0 .44-.293.806-.733.953zm-.733-2.787v1.833c-.367-.146-.734-.366-.734-.88 0-.44.294-.806.734-.953zM12.433 1c.66 0 1.687 1.247 2.2 1.98.66-.587 2.054-1.76 2.934-1.76.366 0 .586.147.66.293C19.253 2.687 16.76 5.4 15.22 6.867H9.647C8.18 5.4 5.613 2.687 6.64 1.513a.93.93 0 01.66-.293c.88 0 2.2 1.173 2.86 1.76.587-.733 1.613-1.98 2.273-1.98z" id="svgicon_money-bag_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_money-bag_a"/>'
  }
})
