











import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import Success from '#/components/modals/Success.vue'
import Error from '#/components/modals/Error.vue'
import ConfirmAction from '#/components/modals/ConfirmAction.vue'
import PaypalGuide from '#/components/modals/PaypalGuide.vue'
import SetVaultPassword from '#/components/modals/SetVaultPassword.vue'
import Subscribe from '#/components/modals/Subscribe.vue'
import { ModalType, ModalStore, IModalParams } from '~/store/modal'

@Component({ components: { Success, ConfirmAction, Error, PaypalGuide, SetVaultPassword, Subscribe } })
export default class ModalManager extends Vue {
    ModalType = ModalType

    @ModalStore.State('activeModal') activeModal: IModalParams

    get showModalOverlay() {
        switch (this.activeModal && this.activeModal.modal) {
            case ModalType.PayPalGuide:
                return true
        }
    }

    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (params: IModalParams) => void
}
