/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cancel': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M22.256 5.333L15.59 12l6.666 6.666a2.539 2.539 0 01-3.589 3.59L12 15.59l-6.667 6.666A2.53 2.53 0 013.54 23a2.539 2.539 0 01-1.795-4.334L8.41 12 1.744 5.333a2.538 2.538 0 013.589-3.59L12 8.41l6.667-6.667a2.539 2.539 0 013.59 3.59z" id="svgicon_cancel_a"/></defs><use _fill="#424242" xlink:href="#svgicon_cancel_a"/>'
  }
})
