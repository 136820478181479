/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M18.411 14.458a.134.134 0 00-.039.118l.828 4.823c.125.73-.17 1.456-.77 1.89a1.929 1.929 0 01-2.037.149l-4.33-2.277a.134.134 0 00-.125 0l-4.33 2.276a1.922 1.922 0 01-2.038-.147 1.925 1.925 0 01-.77-1.891l.828-4.824a.134.134 0 00-.04-.118l-3.503-3.415a1.926 1.926 0 01-.49-1.983 1.924 1.924 0 011.562-1.316L8 7.04a.133.133 0 00.1-.073l2.166-4.388A1.925 1.925 0 0112 1.5c.74 0 1.408.415 1.735 1.078L15.9 6.967c.02.039.056.066.1.072l4.843.704a1.926 1.926 0 011.561 1.317c.229.704.04 1.466-.49 1.982l-3.503 3.416z" id="svgicon_star_a"/></defs><use _fill="#979797" fill-rule="nonzero" xlink:href="#svgicon_star_a"/>'
  }
})
