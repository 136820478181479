/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M9.615 17.923c-1.438 0-2.538 1.1-2.538 2.539C7.077 21.9 8.177 23 9.615 23c1.439 0 2.539-1.1 2.539-2.538 0-1.439-1.1-2.539-2.539-2.539zm0 3.723a1.157 1.157 0 01-1.184-1.184c0-.677.507-1.185 1.184-1.185.677 0 1.185.508 1.185 1.185 0 .676-.508 1.184-1.185 1.184zm8.462-3.723c1.438 0 2.538 1.1 2.538 2.539 0 1.438-1.1 2.538-2.538 2.538-1.439 0-2.539-1.1-2.539-2.538 0-1.439 1.1-2.539 2.539-2.539zm0 3.723c.677 0 1.185-.508 1.185-1.184 0-.677-.508-1.185-1.185-1.185s-1.185.508-1.185 1.185c0 .676.508 1.184 1.185 1.184zm3.215-17.261c.508 0 .931.507.846 1.015l-1.353 6.77c-.085.422-.423.676-.847.676H8.77c-.507 0-.846.339-.846.846 0 .508.339.846.846.846h11.846v1.693H8.854c-1.27 0-2.454-.931-2.623-2.285a2.612 2.612 0 011.607-2.623l-3.553-8.63H2V1h3.385l1.438 3.385h14.47z" id="svgicon_cart_a"/></defs><use xlink:href="#svgicon_cart_a"/>'
  }
})
