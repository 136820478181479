/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'product': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M21.9 16.499v3.099a.375.375 0 01-.047.183C21.392 21.624 17.404 23 12.45 23c-4.965 0-8.96-1.381-9.406-3.232a.355.355 0 01-.044-.17v-3.1c1.563 1.1 4.783 1.966 9.45 1.966 4.666 0 7.887-.865 9.45-1.965zm0-4.915v3.553c-1.076 1.123-4.685 2.159-9.45 2.159S4.076 16.26 3 15.137v-3.553c.495.34 1.136.637 1.884.89 1.592.559 3.803.95 6.578 1.026a30.815 30.815 0 001.973 0c2.767-.075 4.974-.465 6.566-1.021.754-.254 1.4-.553 1.899-.895zm0-4.956v3.554c-.997 1.04-4.17 2.006-8.424 2.142l-.132.003a34.121 34.121 0 01-1.417.008c-.125-.001-.248-.005-.371-.008l-.132-.003C7.17 12.188 3.997 11.222 3 10.18V6.628C4.84 7.892 8.729 8.56 12.45 8.56c3.721 0 7.61-.668 9.45-1.932zM12.45 1c5.713 0 8.975 1.603 9.399 3.229.03.052.051.11.051.173v.872c-1.072 1.119-4.656 2.152-9.45 2.152S4.072 6.393 3 5.274v-.872c0-.069.023-.13.056-.185C3.493 2.595 6.75 1 12.45 1z" id="svgicon_product_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_product_a"/>'
  }
})
