/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fireworks': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M7.003 17.33c-.2 0-.334-.134-.334-.334v-.667c0-.2.133-.334.334-.334.2 0 .333.133.333.334v.667c0 .2-.133.333-.333.333zm0 3.336c.2 0 .333.133.333.333v.667c0 .2-.133.334-.333.334s-.334-.133-.334-.334V21c0-.2.133-.333.334-.333zm-1.669-1.669c0 .2-.133.334-.333.334h-.667c-.2 0-.334-.133-.334-.334 0-.2.133-.333.334-.333H5c.2 0 .333.133.333.333zm3.07-1.067c-.067 0-.134-.067-.2-.134a.322.322 0 010-.467l.467-.4a.322.322 0 01.467 0 .322.322 0 010 .467l-.467.467c-.067.067-.2.067-.267.067zm-3.07 2.268a.322.322 0 01.468 0 .322.322 0 010 .468l-.4.467c-.068.066-.134.066-.268.066-.133 0-.2 0-.267-.066a.322.322 0 010-.467l.467-.468zm3.337 0l.533.468a.322.322 0 010 .467c-.066.066-.133.066-.267.066-.133 0-.2 0-.266-.066l-.467-.467a.322.322 0 010-.468.322.322 0 01.467 0zm-3.337-3.336l.534.467a.322.322 0 010 .467c-.066.067-.133.067-.267.067-.133 0-.2 0-.267-.067l-.467-.467a.322.322 0 010-.467.322.322 0 01.467 0zM20.08 7.988h.4l-.2.6c-.066 0-.133.068-.133.068-.067 0-1.001.667-6.072.733l1.134-3.135c1.402 1.2 3.003 1.734 4.871 1.734zm-7.006 4.004l.734-1.869c3.536 0 5.271-.333 6.072-.6l-.8 2.135c-.935.133-2.736.267-6.006.334zm-.934 2.669l.734-1.935c3.003-.067 4.87-.2 6.005-.334l-.667 1.801c-.6.134-2.202.4-6.072.468zm8.54-7.407v.067H20.08c-1.735 0-3.203-.534-4.537-1.668L19.48 2.05c.066-.067.2-.067.333 0 .067.067.2.133.2.267l.668 4.937zm-6.738 8.941c-.6-.2-1.135-.534-1.602-.867 3.07 0 4.804-.2 5.672-.4l-.6 1.534c-.068.133-.201.2-.334.2h-.4L15.008 21.6c-.067.133-.2.2-.333.2h-.134c-.133-.067-.267-.2-.2-.4l1.601-4.871c-.467-.067-.934-.134-1.4-.267 0 .133-.068.2-.068.267-.533 1.334-1.267 3.403-3.803 3.403-1.267 0-3.67-.668-3.736-.668-.134-.066-.267-.2-.2-.4.066-.133.2-.267.4-.2.067 0 2.402.667 3.603.667 2.002 0 2.669-1.601 3.136-2.936 0-.066.067-.133.067-.2z" id="svgicon_fireworks_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_fireworks_a"/>'
  }
})
