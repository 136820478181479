/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'license-options': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12.362 2C10.033 2 7.856 2.648 6 3.772V22l6.405-4.554L18.811 22V3.827A12.234 12.234 0 0012.36 2zm4.108 7.642l-1.892 1.843.447 2.603a.195.195 0 01-.282.204l-2.337-1.228-2.338 1.228a.194.194 0 01-.282-.204l.447-2.603-1.891-1.843a.195.195 0 01.107-.331l2.614-.38 1.169-2.369c.065-.132.282-.132.348 0l1.169 2.369 2.613.38a.194.194 0 01.108.33z" id="svgicon_license-options_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_license-options_a"/>'
  }
})
