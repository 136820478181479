






















// @ts-nocheck
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import dropin, { PaymentMethodPayload } from 'braintree-web-drop-in'

import { fetchClientToken } from '~/api/payment'
import { cancelProducerSubscription, subscribeProducerToPremiumPlan } from '#/api/payment'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { UserStore } from '#/store/user'
import VLoadSpinner from '~/components/VLoadSpinner.vue'

@Component({ components: { VLoadSpinner } })
export default class SubscribeModal extends Vue {
	@Prop({ type: Boolean }) billYearly!: boolean

	dropinInstance: dropin.Dropin
	paymentError: object = null

	@ModalStore.State('isLoading') isLoading: boolean
	@UserStore.State('authenticatedUser') authenticatedUser: IUserProducer
	@UserStore.Getter('hasEliteSubscription') hasEliteSubscription: boolean
	@ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void
	@ModalStore.Mutation('SET_LOADING_STATE') SET_LOADING_STATE: () => void
	@UserStore.Action('fetchUserProducerInfo') fetchUserProducerInfo: () => void

	get paymentErrorShort() {
		return this.paymentError instanceof String ? this.paymentError.substring(0, 300) : this.paymentError
	}

	async subscribe() {
		try {
			const payload: PaymentMethodPayload = await this.dropinInstance.requestPaymentMethod({
				threeDSecure: {
					amount: this.billYearly ? 99 : 9.99,
					email: this.authenticatedUser.email
				}
			})
			this.SET_LOADING_STATE(true)
			await subscribeProducerToPremiumPlan({ nonce_from_the_client: payload.nonce, bill_yearly: this.billYearly })
			this.fetchUserProducerInfo()
			this.paymentError = null
			location.replace('/')
			this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
		} catch (err) {
			this.paymentError = err
			console.error(err)
		} finally {
			this.SET_LOADING_STATE(false)
		}
	}

	async cancelSubscription() {
		try {
			this.SET_LOADING_STATE(true)
			await cancelProducerSubscription()
			location.replace('/')
		} catch (err) {
			this.paymentError = err
			console.error(err)
		} finally {
			this.SET_LOADING_STATE(false)
		}
	}

	async initBraintree() {
		const clientToken = await fetchClientToken()

		dropin.create(
			{
				authorization: clientToken,
				container: '#dropin-container',
				// @ts-ignore
				threeDSecure: true,
				paypal: {
					flow: 'vault'
				}
			},
			(err: object, instance: dropin.Dropin) => {
				if (err) {
					this.paymentError = err
					console.error(err)
					return
				}
				this.dropinInstance = instance
			}
		)
	}

	created() {
		if (!this.hasEliteSubscription) this.initBraintree()
	}
}
