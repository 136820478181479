/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pencil': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_pencil_a" d="M14.68 4.654l4.475 4.475L7.827 20.457l-4.473-4.475L14.68 4.654zm7.871-1.08l-1.995-1.996a1.98 1.98 0 00-2.798 0L15.846 3.49l4.475 4.476 2.23-2.23a1.526 1.526 0 000-2.162zM1.012 22.272a.51.51 0 00.617.606l4.986-1.21-4.472-4.474-1.13 5.078z"/></defs><use xlink:href="#svgicon_pencil_a"/>'
  }
})
