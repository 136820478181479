
















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { ModalStore } from '~/store/modal'

@Component
export default class ErrorModal extends Vue {
    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: () => void
}
