/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'folders': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12.922 3.567L11.09 1H3.2v1.467H2.1v1.466H1V23h22V3.567H12.922zm-11.189 18.7v-17.6h6.778l1.834 2.566h.004l1.72 2.402c.071.1.184.153.298.154V9.8h9.9v12.467H1.733zm20.534-13.2h-9.703L11.25 7.233h11.016v1.834zm0-2.567H10.726L8.998 4.087l-.11-.154H2.834V3.2h6.778l1.833 2.567h10.823V6.5zM11.822 5.033L9.99 2.467H3.933v-.734h6.778L12.544 4.3h9.723v.733H11.822zM10.9 21.167v-7.334h10.267v7.334H10.9zm.733-6.6v5.866h8.8v-5.866h-8.8zm1.467 1.1h3.667v.733H13.1v-.733zm0 1.466h5.867v.734H13.1v-.734zm0 1.467h5.867v.733H13.1V18.6z" id="svgicon_folders_a"/></defs><use xlink:href="#svgicon_folders_a"/>'
  }
})
