









import Vue from 'vue'
import ModalManager from '#/components/modals/Index.vue'

export default Vue.extend({
    components: { ModalManager },
    computed: {
        routeIsLogin(): boolean {
            return this.$route.name === 'Login'
        }
    },
    mounted() {
        this.$store.dispatch('base/onAppLoad')
    }
})
