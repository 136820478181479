/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M22.173 4.08l-.589-.506a2.374 2.374 0 00-3.31.21L8.419 14.711l-2.975-2.562a2.374 2.374 0 00-3.31.207l-.521.576a2.375 2.375 0 00.17 3.355l5.453 4.993a2.375 2.375 0 003.425-.182L22.425 7.429a2.374 2.374 0 00-.252-3.35z" id="svgicon_check_a"/></defs><use xlink:href="#svgicon_check_a"/>'
  }
})
