/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'store': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 17V7.417c0-.69-.56-1.25-1.25-1.25h-.833v-3.75A.417.417 0 0019.5 2h-15a.417.417 0 00-.417.417v3.75H3.25c-.69 0-1.25.56-1.25 1.25V17c0 .69.56 1.25 1.25 1.25H9.5v1.038a2.463 2.463 0 00-1.625 1.879H7V22h10v-.833h-.875a2.463 2.463 0 00-1.625-1.88V18.25h3.154l.483.804c.067.111.18.185.309.2h.05c.11 0 .217-.043.296-.12l.295-.296 2.063 2.083.583-.588-2.062-2.083h1.079c.69 0 1.25-.56 1.25-1.25zM4.917 2.833h14.166V4.5H4.917V2.833zm0 2.5h14.166v8.334H4.917V5.333zm10.35 15.834H8.733c.146-.5.596-1.25 2.017-1.25H12v-.834h-1.667v-.833h3.334v.833h-.417v.834c1.42 0 1.87.75 2.017 1.25zm3.312-3l-.637-1.059 1.058.638-.42.42zm1.308-.863l-2.945-1.766a.417.417 0 00-.571.57l.783 1.309H3.25A.417.417 0 012.833 17V7.417c0-.23.187-.417.417-.417h.833v6.667h-.416v.833h16.666v-.833h-.416V7h.833c.23 0 .417.187.417.417V17c0 .23-.187.417-.417.417h-.746a.417.417 0 00-.117-.113zM5.775 3.268h.836v.835h-.836v-.835zm1.672 0h.835v.835h-.835v-.835zm1.671 0h.836v.835h-.836v-.835zM4.104 15.373h-.836v1.253c0 .23.187.417.418.417H4.94v-.835h-.836v-.835zm1.671.835v.835h.836v-.835h-.836zM18.24 5.746c.231 0 .418.188.418.418v1.254h-.836v-.836h-.835v-.836h1.253zm-12.896.418c0-.23.187-.418.418-.418h1.253v.836H6.18v.836h-.836V6.164zm3.865 5.55l5.013-5.013.57.57-5.012 5.014-.57-.571zm1.12-2.625a1.24 1.24 0 110-2.478 1.24 1.24 0 010 2.478zm0-1.652a.413.413 0 100 .826.413.413 0 000-.826zm3.343 2.46a1.24 1.24 0 110 2.478 1.24 1.24 0 010-2.479zm0 1.651a.413.413 0 100-.826.413.413 0 000 .826z"/>'
  }
})
