/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'contract': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M16.667 12c.2 0 .333-.133.333-.333V2.333c0-.2-.133-.333-.333-.333H8.533c-.066 0-.066.067-.133.067L3.067 7.4S3 7.467 3 7.533v12.134c0 .2.133.333.333.333h8.8c.134 0 .2-.067.267-.133.067-.067.067-.2.067-.334-.067-.2-.134-.333-.134-.533v-4.667c0-.4.267-.8.6-.933l3.334-1.333c.133-.067.266-.067.4-.067zM8.333 7.333h-4.2l4.2-4.2v4.2zM20.133 14c.134.067.2.2.2.333V19c0 1.267-2.933 2.733-3.533 3h-.267c-.6-.333-3.533-1.733-3.533-3v-4.667c0-.133.067-.266.2-.333l3.333-1.333h.267L20.133 14zm-1.8 4.333V17c0-.2-.133-.333-.333-.333h-1v-2c0-.067-.067-.2-.133-.267-.134-.067-.2-.067-.334 0l-1.333.667c-.133 0-.2.133-.2.266V17c0 .2.133.333.333.333h1v2c0 .134.067.2.134.334.066.066.133.066.2.066.066 0 .066-.066.133-.066.667-.334 1-.667 1.267-.934l.133-.133a.505.505 0 00.133-.267z" id="svgicon_contract_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_contract_a"/>'
  }
})
