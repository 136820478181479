/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'refer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.927 12.097v5.322c0 .994-.804 1.775-1.827 1.775h-6.213v-6.6l2.266 2.27c.073.071.22.071.292.071.073 0 .22 0 .22-.141a.336.336 0 000-.497l-2.266-2.2h7.528zm-9.356.07l-2.265 2.2a.336.336 0 000 .498c.073.07.219.07.292.07.073 0 .22 0 .292-.141l2.266-2.2v6.6H2.827C1.804 19.194 1 18.413 1 17.49v-5.322h12.571zm-2.704 3.478H4.29c-.22 0-.365.142-.365.355s.146.355.365.355h6.578c.22 0 .366-.142.366-.355s-.147-.355-.366-.355zm-2.923-1.42H4.289c-.22 0-.365.143-.365.356s.146.354.365.354h3.655c.219 0 .365-.141.365-.354s-.146-.355-.365-.355zM14.229 5v4.258A5.024 5.024 0 0011.452 7.2c-.512-.213-1.097 0-1.389.426a.97.97 0 00-.22 1.064c.366 1.207 1.097 2.13 2.12 2.697H1V6.774C1 5.781 1.804 5 2.827 5H14.23zm6.87 0c1.024 0 1.828.78 1.901 1.774v4.613h-5.847a4.913 4.913 0 002.12-2.697c.073-.355 0-.78-.22-1.064-.365-.355-.877-.568-1.389-.426-1.242.355-2.192 1.065-2.777 2.058V5zm-9.94 2.839c1.682.496 2.851 1.845 2.924 3.477-1.681-.142-3.07-1.206-3.581-2.839-.073-.212 0-.354.073-.425.073-.142.292-.213.438-.213zm6.87 0c.147 0 .293.07.44.213 0 .07.146.283.073.425-.439 1.633-1.828 2.697-3.582 2.84.146-1.633 1.243-2.982 2.924-3.478z" _fill="#000" fill-rule="nonzero"/>'
  }
})
