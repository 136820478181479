/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'reorder': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M21.34 17.232a1.661 1.661 0 010 3.322H8.056a1.661 1.661 0 010-3.322H21.34zm0-6.611a1.661 1.661 0 010 3.322H8.056a1.66 1.66 0 010-3.322H21.34zM8.056 7.33a1.661 1.661 0 010-3.321H21.34a1.66 1.66 0 010 3.322H8.056zm-5.37.04a1.686 1.686 0 110-3.371 1.686 1.686 0 010 3.372zm0 6.597a1.686 1.686 0 110-3.371 1.686 1.686 0 010 3.37zm0 6.611a1.686 1.686 0 110-3.372 1.686 1.686 0 010 3.372z" id="svgicon_reorder_a"/></defs><use xlink:href="#svgicon_reorder_a"/>'
  }
})
