/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_search_a" d="M22.462 19.862l-4.78-4.78c-.025-.025-.055-.043-.082-.066a9.045 9.045 0 10-2.585 2.585c.024.026.042.056.067.081l4.78 4.78a1.839 1.839 0 002.6-2.6zm-12.417-3.908a5.91 5.91 0 110-11.818 5.91 5.91 0 010 11.818z"/></defs><use xlink:href="#svgicon_search_a"/>'
  }
})
