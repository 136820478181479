















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import { ModalStore, IModalParams } from '~/store/modal'
import { UserStore } from '#/store/user'

@Component
export default class SetVaultPasswordModal extends Vue {
	password: string = ''

	@ModalStore.State('activeModal') activeModal: IModalParams
	@UserStore.State('authenticatedUser') authenticatedUser: any

	@ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (params: IModalParams) => void
	@UserStore.Mutation('SET_PRODUCER_USER_INFO') SET_PRODUCER_USER_INFO: (user: Partial<IUserProducer>) => void

	updateVaultPassword() {
		this.SET_PRODUCER_USER_INFO({ ...this.authenticatedUser, private_beats_password: this.password })
		this.activeModal.callback()
	}
}
