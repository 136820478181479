/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'selling-preferences': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M19.971 5.994c.317.597.474 1.336.474 2.223 0 2.015-.85 3.64-2.545 4.874-1.698 1.236-4.068 1.852-7.114 1.852h-.713c-.537 0-1.072.421-1.193.935l-.856 3.65c-.12.516-.656.937-1.192.937H5.273l-.14.596c-.12.518.22.939.755.939h2.499c.536 0 1.073-.421 1.191-.937l.857-3.65c.12-.514.656-.936 1.193-.936h.713c3.046 0 5.416-.615 7.113-1.851C21.151 13.392 22 11.767 22 9.752c0-.887-.158-1.626-.474-2.223a3.762 3.762 0 00-1.373-1.446c-.064-.038-.115-.052-.182-.089zM6.469 17.993l.857-3.65c.12-.514.656-.935 1.192-.935h.713c3.047 0 5.417-.616 7.114-1.852 1.697-1.234 2.545-2.859 2.545-4.874 0-.886-.157-1.626-.474-2.222a3.757 3.757 0 00-1.372-1.446c-.613-.374-1.332-.635-2.166-.787C14.042 2.076 13.064 2 11.941 2H6.737c-.538 0-1.073.419-1.195.935L2.025 17.993c-.122.516.218.937.753.937h2.5c.535 0 1.072-.421 1.191-.937zm2.04-8.737l.752-3.196c.121-.515.658-.936 1.192-.936h.818c.953 0 1.676.16 2.16.48.485.316.728.803.728 1.464 0 1-.378 1.772-1.128 2.313-.751.54-1.795.814-3.144.814h-.62c-.536 0-.875-.423-.757-.939z" id="svgicon_selling-preferences_a"/></defs><use _fill="#030104" fill-rule="nonzero" xlink:href="#svgicon_selling-preferences_a"/>'
  }
})
