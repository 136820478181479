import { get } from '~/services/http-requests'

const _BS_INSTRUMENTS = 'instruments'

/*
 **** GET ****
 */
function fetchAllInstrumentsLight(): Promise<IInstrumentLight[]> {
    return get({ fullUrl: `api/${_BS_INSTRUMENTS}.json?fields=name,id,image_thumbnail_url`, isFreeRequest: true })
}

export { fetchAllInstrumentsLight }
