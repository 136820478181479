import { get, update } from '~/services/http-requests'

const _BS_OWN_PRODUCER = 'producer'

/*
 **** GET ****
 */
async function fetchOwnProducer(): Promise<IUserProducer> {
    const [first] = await get({ baseProperty: _BS_OWN_PRODUCER })
    return first
}

/*
 **** PATCH ****
 */
function updateProducer(user: Partial<IUserProducer>): Promise<IUserProducer> {
    return update({ baseProperty: _BS_OWN_PRODUCER, fullUrl: `${_BS_OWN_PRODUCER}/${user.id}/`, dispatchParams: user })
}

export { fetchOwnProducer, updateProducer }
