/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'upload': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M20.63 12.691l.606-4.4c.02-.151.057-.417-.038-.512a.681.681 0 00-.455-.208h-1.536V4.953c0-.284-.114-.436-.398-.436h-7.264L9.383 3.114C9.288 3.019 9.155 3 9.023 3H3.2c-.284 0-.303.152-.303.436v4.116H1.53c-.152 0-.303.038-.398.151-.095.114-.114.418-.095.57l2.03 11.075c.037.247.246.36.492.36h9.73c.929 1.48 2.598 2.561 4.457 2.561 2.882 0 5.234-2.333 5.234-5.216 0-1.84-.93-3.432-2.352-4.362zM4.413 7.551V4.518h4.419l2.162 1.404a.568.568 0 00.341.113h6.354v1.518H4.414zm13.333 13.732a4.233 4.233 0 01-4.23-4.23 4.233 4.233 0 014.23-4.229 4.233 4.233 0 014.229 4.23 4.233 4.233 0 01-4.23 4.229zm.417-6.43a.465.465 0 00-.607 0l-2.56 2.087c-.21.17-.247.474-.076.663.17.21.436.228.645.076L17.31 16.2v3.414c0 .265.304.474.57.474.265 0 .568-.209.568-.474v-3.376l1.707 1.422a.417.417 0 00.626-.076.484.484 0 00-.076-.682l-2.541-2.049z" id="svgicon_upload_a"/></defs><use xlink:href="#svgicon_upload_a"/>'
  }
})
