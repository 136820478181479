/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'file': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M16.543 7.123h4.724v14.655c0 .675-.547 1.222-1.222 1.222H5.989a1.222 1.222 0 01-1.222-1.222V16.74h9.87c.924 0 1.674-.75 1.674-1.675V9.95c0-.925-.75-1.675-1.674-1.675h-9.87V2.222C4.767 1.547 5.315 1 5.989 1h8.926v4.494c0 .898.73 1.63 1.628 1.63zm0-.858a.771.771 0 01-.77-.77v-3.35l4.288 4.12h-3.518zm-1.427 8.383c0 .78-.632 1.41-1.41 1.41H3.41A1.41 1.41 0 012 14.648v-4.309c0-.779.631-1.41 1.41-1.41h10.296c.778 0 1.41.631 1.41 1.41v4.309zm-8.429-1.916c.18-.151.27-.372.27-.664a.976.976 0 00-.066-.37.731.731 0 00-.498-.44 1.954 1.954 0 00-.509-.05h-.745c-.128 0-.221.027-.28.081-.058.055-.088.146-.088.274v2.21c0 .115.027.202.08.261a.27.27 0 00.211.09.27.27 0 00.208-.09c.054-.06.082-.149.082-.265v-.811h.532c.356 0 .624-.076.803-.226zm3.165.372a2.28 2.28 0 00.045-.47c0-.522-.15-.91-.448-1.166a.933.933 0 00-.383-.206 2.04 2.04 0 00-.507-.055h-.757c-.125 0-.216.029-.272.085-.056.056-.084.146-.084.271v2.103a.83.83 0 00.025.226.224.224 0 00.104.134.471.471 0 00.24.048h.756c.132 0 .25-.008.355-.025.105-.017.203-.046.294-.088a.988.988 0 00.253-.166c.097-.09.178-.192.24-.306a1.39 1.39 0 00.139-.385zm-.988-1.38a.57.57 0 01.316.27c.085.14.128.352.128.635 0 .401-.097.684-.291.849a.461.461 0 01-.153.088.7.7 0 01-.17.039c-.055.005-.13.008-.227.008h-.44v-1.946h.383c.178 0 .329.019.454.057zm-3.121-.08c.26 0 .43.042.514.126.075.08.112.183.112.31a.419.419 0 01-.073.256.401.401 0 01-.21.14 1.173 1.173 0 01-.343.043h-.391v-.876h.39zm6.584-.05a.209.209 0 00.067-.164.207.207 0 00-.067-.161c-.045-.041-.114-.062-.206-.062h-1.37a.486.486 0 00-.202.036.23.23 0 00-.116.116.483.483 0 00-.037.204v2.207c0 .118.027.207.08.266a.273.273 0 00.211.088c.084 0 .154-.03.208-.088.054-.057.081-.147.081-.267v-.944h.957c.087 0 .153-.02.197-.059a.201.201 0 00.067-.158.205.205 0 00-.066-.16c-.043-.04-.11-.06-.198-.06h-.957v-.733h1.145c.092 0 .161-.02.206-.061z" id="svgicon_file_a"/></defs><use xlink:href="#svgicon_file_a"/>'
  }
})
