/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'drums': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M12.175 16.026a25.354 25.354 0 006.014.003l-3.007 4.097-3.007-4.1zm10.865 2.5l-2.838-2.835c1.12-.25 2.093-.6 2.838-1.033v3.869zm-3.68-1.586l2.957 2.964c-1.035.59-3.162 1.196-6.097 1.314l3.14-4.278zM8.07 19.902l2.95-2.948 3.124 4.26c-2.923-.12-5.038-.725-6.074-1.312zm7.125 2.82c3.04 0 6.096-.53 7.847-1.55v.419C22.788 22.937 19.364 24 15.195 24c-4.167 0-7.59-1.065-7.843-2.409v-.419c1.748 1.02 4.804 1.55 7.843 1.55zm-7.843-4.197v-3.867c.743.434 1.717.78 2.833 1.033l-2.833 2.834zM19.439 9.4c1.934.408 3.337 1.088 3.602 1.876v1.375c0 .73-2.98 2.071-7.847 2.071-4.865 0-7.844-1.34-7.844-2.071v-1.375c.133-.399.595-.768 1.218-1.09l.73.268c-.896.358-1.393.764-1.393 1.105 0 .82 2.839 2.013 7.288 2.013 4.45 0 7.291-1.193 7.291-2.013 0-.526-1.17-1.204-3.18-1.623.068-.172.12-.351.135-.536zm-3.109-.273l-7.518-5.97a.65.65 0 01.807-1.016l7.52 5.97c.122-.04.251-.06.378-.06.26 0 .52.083.738.256a1.19 1.19 0 11-1.925.821zm-2.591 2.898a1.475 1.475 0 01-.933-1.065L1.531 6.798a.812.812 0 11.564-1.524l11.274 4.162a1.496 1.496 0 011.4-.204 1.489 1.489 0 01-1.03 2.794z" id="svgicon_drums_a"/></defs><use xlink:href="#svgicon_drums_a"/>'
  }
})
