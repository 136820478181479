/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'picture': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M23.148 10.523L18.89 7.826c-.142-.071-.213-.071-.355 0a.39.39 0 00-.212.355v9.013c0 .567-.497 1.064-1.065 1.064h-6.316c-.142 0-.284.071-.355.284-.07.142 0 .284.142.426l5.606 3.548c.071.071.142.071.213.071h.071c.071 0 .142-.07.213-.142L23.22 11.09c.142-.284.071-.496-.07-.567zm-5.96 7.025c.283 0 .425-.142.425-.354V3.71c0-.213-.142-.355-.355-.355H2.355c-.213 0-.355.142-.355.355v13.484c0 .07 0 .141.071.141 0 .071.07.071.07.071h.072c.07 0 .07.071.142.071 0 .071 14.832.071 14.832.071zm-.285-13.483v11.78l-4.329-8.729c-.07-.142-.213-.213-.284-.213-.142 0-.284.071-.284.213l-2.98 5.89-2.13-2.412a.538.538 0 00-.283-.142c-.142 0-.213.07-.284.142l-3.62 5.393V4.065h14.194zM7.867 6.004a1.774 1.774 0 11-2.509 2.509 1.774 1.774 0 012.51-2.51" id="svgicon_picture_a"/></defs><use xlink:href="#svgicon_picture_a"/>'
  }
})
