














































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { UploadType } from '~/store/upload'
import { UserStore } from '#/store/user'

@Component
export default class Sidebar extends Vue {
	menuIsActive = false
	UploadType = UploadType

	@UserStore.Getter('toplineBannerShow') toplineBannerShow: boolean

	get routeName() {
		return this.$route.name
	}

	updatedActiveMenu() {
		if (
			this.$route.name === 'BeatsUpload' ||
			this.$route.name === 'BeatsCatalog' ||
			this.$route.name === 'BeatsReorder' ||
			this.$route.name === 'BeatsPrivateVault'
		)
			this.menuIsActive = true
		else this.menuIsActive = false
	}

	@Watch('routeName')
	onRouteNameChange() {
		this.updatedActiveMenu()
	}

	created() {
		this.updatedActiveMenu()
	}
}
