/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'diamond': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.665 10.026l4.105 10.693-9.616-10.693h5.51zm16.181 0l-9.55 10.627 4.28-10.627h5.27zm-6.52 0l-4.304 10.56-4.062-10.56h8.365zM14.173 3l2.503 6.148H7.63L10.22 3h3.953zM8.948 3L6.38 8.95c0 .022 0 .198-.022.198H1l2.218-5.643c.087-.22.307-.505.548-.505h5.182zm11.286 0c.241 0 .46.285.57.505L23 9.148h-5.072c-.022 0 0-.198-.022-.22L15.447 3z" _fill="#000" fill-rule="nonzero"/>'
  }
})
