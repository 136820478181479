/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'zip': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M17.577 8.123H22.3v14.655c0 .675-.547 1.222-1.222 1.222H7.022A1.222 1.222 0 015.8 22.778v-5.215h9.87c.924 0 1.674-.75 1.674-1.674v-5.116c0-.925-.75-1.675-1.674-1.675H5.8V3.222C5.8 2.547 6.348 2 7.022 2h8.926v4.494c0 .898.73 1.63 1.629 1.63zm0-.858a.771.771 0 01-.771-.77v-3.35l4.288 4.12h-3.517zm-6.221 5.216c.26 0 .43.042.514.127.074.08.111.182.111.309a.419.419 0 01-.072.256.399.399 0 01-.21.14 1.169 1.169 0 01-.343.044h-.392v-.876h.392zm4.76 3.004a1.41 1.41 0 01-1.41 1.41H4.41A1.41 1.41 0 013 15.486v-4.309c0-.779.631-1.41 1.41-1.41h10.296c.778 0 1.41.631 1.41 1.41v4.31zm-7.418-.634a.211.211 0 00.066-.165.191.191 0 00-.066-.155c-.045-.037-.112-.056-.202-.056H6.98l1.377-1.639c.107-.125.178-.215.213-.272a.418.418 0 00.053-.224c0-.197-.106-.296-.317-.296H6.674c-.089 0-.156.02-.2.062a.21.21 0 00-.068.161c0 .064.022.115.067.155.045.039.112.058.2.058H7.94l-1.473 1.763-.082.096a1.49 1.49 0 00-.073.092.311.311 0 00-.042.077.254.254 0 00-.016.09c0 .096.032.172.096.228.064.057.159.085.285.085h1.862c.09 0 .157-.02.202-.06zm1.036-.245v-2.259c0-.118-.027-.207-.081-.265a.276.276 0 00-.212-.087.268.268 0 00-.207.088c-.054.06-.08.147-.08.264v2.26c0 .117.026.205.08.264.055.06.123.09.207.09.087 0 .158-.03.212-.089.054-.058.081-.147.081-.266zM12.3 13.57c.18-.15.27-.372.27-.664a.976.976 0 00-.066-.37.73.73 0 00-.499-.44 1.955 1.955 0 00-.508-.05h-.745c-.128 0-.221.027-.28.082-.059.054-.088.145-.088.273v2.21c0 .115.027.202.08.262a.27.27 0 00.211.088c.084 0 .153-.03.208-.09.054-.06.082-.148.082-.264v-.811h.532c.355 0 .623-.076.803-.226z" id="svgicon_zip_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_zip_b" _fill="#fff"><use xlink:href="#svgicon_zip_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_zip_a"/><g mask="url(#svgicon_zip_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
