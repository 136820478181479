import Vue from 'vue'

import { namespace } from 'vuex-class'
import { fetchLicenseOptionsLight } from '#/api/license-option'
import { VuexActionData } from '~/interfaces/interface-utils'
import { fetchAllArtistTypesLight } from '~/api/artist-type'
import { fetchAllGenresLight } from '~/api/genre'
import { fetchAllInstrumentsLight } from '~/api/instrument'

export const BaseStore = namespace('base')

interface State {
    licenseOptionsLight: ILicenseOptionLight[]
    artistTypes: IArtistType[]
    genres: IGenre[]
    instruments: IInstrument[]
}

export default {
    namespaced: true,
    state: {
        licenseOptionsLight: [],
        artistTypes: [],
        genres: [],
        instruments: []
    } as State,
    mutations: {
        SET_LICENSE_OPTIONS_LIGHT: (state: State, payload: ILicenseOptionLight[]) => (state.licenseOptionsLight = payload),
        SET_ARTIST_TYPES: (state: State, payload: IArtistType[]) => (state.artistTypes = payload),
        SET_GENRES: (state: State, payload: IGenre[]) => (state.genres = payload),
        SET_INSTRUMENTS: (state: State, payload: IInstrument[]) => (state.instruments = payload)
    },
    actions: {
        async onAppLoad({ rootState, dispatch }: VuexActionData<State>) {
            Vue.$axios.defaults.headers.common.Authorization = `Token ${rootState.auth.token_id}`
            await dispatch('user/fetchUserProducerInfo', null, { root: true })
            await dispatch('fetchLicenseOptionsLight')
        },
        async fetchLicenseOptionsLight({ commit }: VuexActionData<State>) {
            commit('SET_LICENSE_OPTIONS_LIGHT', await fetchLicenseOptionsLight())
        },
        async fetchAllArtistTypesLight({ commit }: VuexActionData<State>) {
            commit('SET_ARTIST_TYPES', await fetchAllArtistTypesLight())
        },
        async fetchAllGenresLight({ commit }: VuexActionData<State>) {
            commit('SET_GENRES', await fetchAllGenresLight())
        },
        async fetchAllInstrumentsLight({ commit }: VuexActionData<State>) {
            commit('SET_INSTRUMENTS', await fetchAllInstrumentsLight())
        }
    }
}
