/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'co-producer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M15.162 12.783a3.227 3.227 0 100-6.453 3.227 3.227 0 000 6.453zm1.369.22h-2.738a4.136 4.136 0 00-4.131 4.132v3.348l.008.052.231.072c2.174.68 4.063.906 5.617.906 3.036 0 4.796-.866 4.905-.92l.215-.11h.023v-3.348a4.135 4.135 0 00-4.13-4.132zM9.5 9.453c.63 0 1.218-.185 1.716-.498a3.993 3.993 0 011.5-2.547c.004-.06.01-.12.01-.181A3.226 3.226 0 109.5 9.453zm2.897 2.989a3.979 3.979 0 01-1.226-2.754c-.1-.008-.2-.016-.303-.016H8.13A4.136 4.136 0 004 13.803v3.349l.009.051.23.073c1.744.545 3.3.796 4.652.875V17.14a4.91 4.91 0 013.507-4.698z" id="svgicon_co-producer_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_co-producer_a"/>'
  }
})
