/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'coupon': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M21.56 10.844c0-.294.146-.587.293-.807.074-.074.074-.147.074-.294 0-.073-.074-.22-.074-.293L14.44 2.11c-.073-.073-.146-.073-.293-.073s-.22.073-.294.073c-.44.44-1.1.44-1.541 0a.355.355 0 00-.514 0C9.596 4.312 4.385 9.596 2.11 11.798a.355.355 0 000 .514c.22.22.294.514.294.734 0 .293-.147.587-.294.807-.073.074-.073.147-.073.294 0 .073.073.22.073.293l7.486 7.487c.074.073.147.073.294.073s.22-.073.293-.073c.44-.44 1.175-.44 1.542 0 .073.073.147.073.293.073.147 0 .22-.073.294-.073L22 12.239c.073-.074.073-.147.073-.294 0-.073-.073-.22-.073-.294-.294-.22-.44-.513-.44-.807zm-9.468-6.899a.355.355 0 01.514 0l.733.734a.355.355 0 010 .514c0 .146-.146.146-.22.146-.073 0-.22 0-.293-.073l-.734-.734a.81.81 0 010-.587zm-6.753 9.394c-.073.074-.22.074-.293.074-.074 0-.22 0-.294-.074l-.734-.733a.355.355 0 010-.514.355.355 0 01.514 0l.734.734c.147.073.147.367.073.513zm3.303 3.303c-.073.074-.147.074-.293.074-.147 0-.22 0-.294-.074l-.734-.734a.355.355 0 010-.514.355.355 0 01.514 0l.734.734c.147.074.147.367.073.514zm3.303 3.303c-.073.073-.22.073-.294.073-.073 0-.22 0-.293-.073l-.734-.734a.355.355 0 010-.514.355.355 0 01.514 0l.734.734c.146.074.146.367.073.514zm4.477-7.193l-2.789.44-.44 2.79a.316.316 0 01-.294.293h-.073c-.147 0-.294-.073-.294-.22l-1.248-2.495-2.789.44a.404.404 0 01-.367-.22c-.073-.147-.073-.294.074-.44l1.981-1.982-1.247-2.496c-.074-.146-.074-.293.073-.44.147-.147.294-.147.44-.073l2.496 1.247 1.982-1.981c.146-.147.293-.147.44-.074.147.074.22.22.22.367l-.44 2.79 2.495 1.247c.147.073.22.22.22.367-.146.294-.293.44-.44.44zm.294-4.183c-.074.073-.147.073-.294.073s-.22 0-.294-.073l-.734-.734a.355.355 0 010-.514.355.355 0 01.514 0l.734.734c.147.073.147.367.074.514zm3.302 3.303c-.073.073-.22.073-.293.073-.074 0-.22 0-.294-.073l-.734-.734a.355.355 0 010-.514.355.355 0 01.514 0l.734.734c.147.073.147.367.073.514z" id="svgicon_coupon_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_coupon_a"/>'
  }
})
