import { post } from '~/services/http-requests'

const _BS_PAYMENT = 'payment'

export interface ISubscribeParams {
    nonce_from_the_client?: string
}

export function subscribeProducerToPremiumPlan(params: ISubscribeParams): Promise<void> {
    return post({ fullUrl: `${_BS_PAYMENT}/subscribe_producer_to_premium_plan/`, dispatchParams: params, isFreeRequest: true })
}

export function cancelProducerSubscription(): Promise<void> {
    return post({ fullUrl: `${_BS_PAYMENT}/cancel_producer_subscription/`, isFreeRequest: true })
}

export async function initPromoSession(params: InitPromoSessionParams): Promise<number> {
    const sessionData = await post({ fullUrl: `${_BS_PAYMENT}/init_session/`, dispatchParams: params, isFreeRequest: true })
    return sessionData.order_id
}

export async function initPromoBraintree(orderId: number, paymentParams: IPaymentParams): Promise<IOrderDataSimple> {
    return post({ fullUrl: `${_BS_PAYMENT}/init_braintree_beat_promotion/${orderId}/`, dispatchParams: paymentParams, isFreeRequest: true })
}
