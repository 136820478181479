/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'code': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M21.635 2.053H2.32A1.32 1.32 0 001 3.373v17.306c0 .727.592 1.319 1.32 1.319h19.315a1.32 1.32 0 001.32-1.32V3.374a1.32 1.32 0 00-1.32-1.32zM17.657 4.27c0-.27.218-.487.487-.487h.488c.269 0 .487.218.487.487v.552a.487.487 0 01-.487.487h-.488a.487.487 0 01-.487-.487V4.27zm-2.265 0c0-.27.218-.487.487-.487h.487c.27 0 .487.218.487.487v.552a.487.487 0 01-.487.487h-.487a.487.487 0 01-.487-.487V4.27zm5.98 16.146H2.582V6.77H21.37v13.644zm.012-15.594a.487.487 0 01-.487.487h-.487a.487.487 0 01-.487-.487V4.27c0-.27.218-.487.487-.487h.487c.269 0 .487.218.487.487v.552zM5.713 14.108a.69.69 0 01-.397-.623v-.049c0-.266.155-.51.397-.622l3.53-1.642a.69.69 0 01.66.044.683.683 0 01.317.578v.018c0 .266-.156.51-.398.622l-2.208 1.027 2.208 1.026a.688.688 0 01.398.622v.018a.684.684 0 01-.687.686.683.683 0 01-.289-.063l-3.531-1.642zm4.538 3.344a.69.69 0 01-.1-.613l2.327-7.209a.684.684 0 01.654-.477h.017a.689.689 0 01.654.896l-2.327 7.208a.684.684 0 01-.653.477h-.018a.689.689 0 01-.554-.282zm3.484-5.64v-.018a.683.683 0 01.687-.686c.1 0 .2.022.288.064l3.532 1.642a.689.689 0 01.397.622v.05a.69.69 0 01-.397.621L14.71 15.75a.684.684 0 01-.659-.043.683.683 0 01-.317-.579v-.018c0-.266.156-.51.397-.622l2.209-1.026-2.209-1.026a.69.69 0 01-.397-.623z" id="svgicon_code_a"/></defs><use xlink:href="#svgicon_code_a"/>'
  }
})
