/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'beats': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M20.407 16.123V2.701c0-.218-.145-.508-.363-.58-.218-.146-.435-.146-.653-.073L8.508 6.764c-.29.072-.435.362-.435.653v9.722c-.363-.145-.871-.218-1.306-.218-.363 0-.726.073-1.089.145-1.814.436-2.974 1.887-2.612 3.193C3.284 21.274 4.445 22 5.823 22c.363 0 .726-.073 1.089-.145 1.596-.435 2.684-1.524 2.684-2.757V10.39l9.432-3.772v7.545c-.363-.145-.87-.217-1.306-.217-.363 0-.725.072-1.088.145-1.814.435-2.975 1.886-2.612 3.192.218 1.016 1.379 1.741 2.757 1.741.363 0 .726-.072 1.088-.145 1.451-.435 2.54-1.596 2.54-2.757zM9.524 8.868V7.852l9.432-4.063v1.306L9.524 8.868z" id="svgicon_beats_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_beats_a"/>'
  }
})
