/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'network': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M6.667 14.333c-.2 0-.334-.133-.334-.333V7.333c0-.2.134-.333.334-.333H8c.2 0 .333.133.333.333S8.2 7.667 8 7.667H7V14c0 .2-.133.333-.333.333zM18 7.667c-.2 0-.333-.134-.333-.334S17.8 7 18 7h1.333c.2 0 .334.133.334.333V14c0 .2-.134.333-.334.333S19 14.2 19 14V7.667h-1zM7 19a2 2 0 110-4 2 2 0 010 4zm2.067.067c1.2.866 1.933 2.266 1.933 3.6 0 .2-.133.333-.333.333H3.333c-.2 0-.333-.133-.333-.333 0-1.334.733-2.734 1.933-3.6a.425.425 0 01.4 0c1 .8 2.334.8 3.334 0a.425.425 0 01.4 0zM19 19a2 2 0 110-4 2 2 0 010 4zm2.067.067c1.2.866 1.933 2.266 1.933 3.6 0 .2-.133.333-.333.333h-7.334c-.2 0-.333-.133-.333-.333 0-1.334.733-2.734 1.933-3.6a.425.425 0 01.4 0c1 .8 2.334.8 3.334 0a.425.425 0 01.4 0zM13 7a2 2 0 110-4 2 2 0 010 4zm-3.667 4c-.2 0-.333-.133-.333-.333 0-1.334.733-2.734 1.933-3.6a.425.425 0 01.4 0c1 .8 2.334.8 3.334 0a.425.425 0 01.4 0c1.2.866 1.933 2.266 1.933 3.6 0 .2-.133.333-.333.333H9.333z" id="svgicon_network_a"/></defs><use xlink:href="#svgicon_network_a"/>'
  }
})
