/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'addon': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M19.985 9.222c-.606 0-.962.233-1.308.459a.507.507 0 01-.781-.462l.435-3.848A.332.332 0 0018 5h-3.887a.755.755 0 00-.636 1.16c.17.266.292.484.292.861 0 .736-.777 1.312-1.768 1.312-.992 0-1.768-.576-1.768-1.312 0-.378.12-.595.291-.861A.752.752 0 009.889 5H6a.334.334 0 00-.331.37l.434 3.839a.495.495 0 01-.13.387c-.166.18-.396.251-.681.065-.315-.206-.671-.439-1.277-.439C2.903 9.222 2 10.319 2 11.667c0 1.348.903 2.444 2.015 2.444.606 0 .962-.233 1.308-.458a.507.507 0 01.781.462l-.435 3.848a.332.332 0 00.331.37h3.887a.755.755 0 00.636-1.16c-.17-.266-.292-.484-.292-.861 0-.735.777-1.311 1.768-1.311.992 0 1.768.576 1.768 1.311 0 .378-.12.595-.291.861a.752.752 0 00.635 1.16H18a.334.334 0 00.331-.37l-.434-3.838a.495.495 0 01.13-.388c.166-.18.396-.251.681-.064.315.206.671.438 1.277.438 1.112 0 2.015-1.096 2.015-2.444 0-1.348-.903-2.445-2.015-2.445z" id="svgicon_addon_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_addon_a"/>'
  }
})
