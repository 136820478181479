/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'newsletter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M19.781 13.028a3.233 3.233 0 011.504.371c.207.11.402.244.583.4.065.055.13.111.19.172a3.224 3.224 0 010 4.554 3.198 3.198 0 01-2.276.943 3.2 3.2 0 01-2.811-1.654 3.203 3.203 0 01-.38-1.167 3.215 3.215 0 013.19-3.62zm.024.961l-2.387 2.567.098.091.33.307.15-.162 1.516-1.631v2.853a.292.292 0 00.584 0v-2.855l.026.029 1.641 1.766.428-.398-1.615-1.737-.771-.83zm1.927-8.182c.088.186.136.392.136.603v6.431a3.93 3.93 0 00-.583-.299 3.933 3.933 0 00-.584-.182 3.973 3.973 0 00-3.747 1.061 3.991 3.991 0 00-.848 4.393H2.41c-.777 0-1.41-.632-1.41-1.409V6.41c0-.2.042-.39.117-.562a.553.553 0 00.088.078l.405.287.557.396 9.022 6.406c.129.092.362.092.49 0l9.022-6.449.546-.39.388-.277a.547.547 0 00.097-.092zM20.458 5c.205 0 .405.045.588.129l-.492.351-.144.104-.816.583L11.434 12 3.218 6.167l-.82-.582-.113-.08-.502-.356c.189-.095.4-.149.626-.149z" id="svgicon_newsletter_a"/></defs><use _fill="#231F20" fill-rule="nonzero" xlink:href="#svgicon_newsletter_a"/>'
  }
})
