




















































































































































import { Vue, Component } from 'vue-property-decorator'
import { UploadType } from '~/store/upload'
import { AuthStore } from '~/store/auth'
import { UserStore } from '#/store/user'
import { ENV_IS_DEVELOPMENT, ENV_IS_STAGING } from '~/const/environment'

@Component
export default class Navbar extends Vue {
    UploadType = UploadType
    isSearch = false
    showMobileMenu = false

    @UserStore.State('authenticatedUser') authenticatedUser: IUserProducer
    @AuthStore.Getter('isAuthenticated') isAuthenticated: boolean
    @UserStore.Getter('toplineBannerShow') toplineBannerShow: boolean

    @UserStore.Mutation('HIDE_TOPLINE_BANNER') HIDE_TOPLINE_BANNER: () => void
    @AuthStore.Action('logout') logout: () => void
    @UserStore.Action('resetAuthenticatedUser') resetAuthenticatedUser: () => void

    finalLogout() {
        this.resetAuthenticatedUser()
        this.logout()
        // send user to marketplace
        setTimeout(() => (window.location.href = ENV_IS_DEVELOPMENT || ENV_IS_STAGING ? 'https://test.thecharts.com' : 'https://thecharts.com'), 1000)
    }

    goToProfile() {
        this.$router.push({ name: 'Settings' })
    }

    toggleMobileMenu(itemToToggle: any) {
        // if client width is less then 1000 (tablet on mobile TODO test tablet)
        if (this.$el.clientWidth <= 1000) {
            this.showMobileMenu = !this.showMobileMenu
            // add remove class
            // if (this.showMobileMenu) document.body.classList.add('noScroll')
            // else document.body.classList.remove('noScroll')
            // open / close search & block body scroll if the search is open
            if (itemToToggle === 'search' && this.showMobileMenu === true) this.isSearch = true
            else if (this.isSearch === true) this.isSearch = false
        }
    }
}
