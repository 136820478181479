import { namespace } from 'vuex-class'
import { BASE_URL } from '~/const/base-url'
import { AudioFileType } from '~/const/audio-file-type'

export const NUM_OF_BEATS_PER_PAGE = 20

export const BeatStore = namespace('beat')

interface State {
    selectedBeats: IBeatProducer[]
    selectDeselectIsActive: boolean
}

export default {
    namespaced: true,
    state: {
        selectedBeats: [],
        selectDeselectIsActive: false
    } as State,
    mutations: {
        SET_OR_RESET_SELECTED_BEATS: (state: State, payload: IBeatProducer) => {
            if (payload === null) state.selectedBeats = []
            else {
                const indexOfElementToAdd = state.selectedBeats.findIndex(item => item.id === payload.id)
                indexOfElementToAdd !== -1 ? state.selectedBeats.splice(indexOfElementToAdd, 1) : state.selectedBeats.push(payload)
            }
        },
        TOGGLE_FULL_SELECT_DESELECT: (state: State, beatsToToggle: IBeatProducer[]) => {
            state.selectDeselectIsActive ? (state.selectedBeats = []) : (state.selectedBeats = beatsToToggle)
            state.selectDeselectIsActive = !state.selectDeselectIsActive
        }
    },
    actions: {
        downloadFileType({ rootState }: any, { beatId, fileType }: { beatId: IBeat['id']; fileType: AudioFileType }) {
            const url = `${BASE_URL}/s3/producer_beat_download/${beatId}/${fileType}/`
            window.open(url)
        }
    }
}
