/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'box': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<defs><path pid="0" d="M19.17 1L23 7.128V23H1V7.128L4.83 1h14.34zm-4.603 21.267h7.7V7.6h-7.7v3.085l-1.1-1.1L12 11.052l-1.467-1.467-1.1 1.1V7.6h-7.7v14.667h7.7v-2.719l1.1 1.1L12 19.182l1.467 1.466 1.1-1.1v2.719zm4.197-20.534h-4.197v5.134h7.405l-3.208-5.134zm-6.764 0h-1.833v5.134h3.666V1.733H12zM10.167 7.6v1.315l.366-.367L12 10.015l1.467-1.467.366.367V7.6h-.366a.367.367 0 01-.734 0h-1.466a.367.367 0 01-.734 0h-.366zm-4.93-5.867l-3.21 5.134h7.405V1.733H5.236zm4.93 20.534v-.949l.366.367L12 20.218l1.467 1.467.366-.367v.949h-3.666zM8.333 18.6V12h7.334v6.6H8.333zm.734-5.867v5.134h5.866v-5.134H9.067zm3.3 3.667a.367.367 0 110 .733h-2.2a.367.367 0 110-.733h2.2zm1.466 0a.367.367 0 110 .733h-.366a.367.367 0 110-.733h.366zm0-2.933a.367.367 0 110 .733h-2.2a.367.367 0 110-.733h2.2zm0 1.466a.367.367 0 110 .734H13.1a.367.367 0 110-.734h.733zm-3.666.734a.367.367 0 110-.734h.733a.367.367 0 110 .734h-.733zm0-1.467a.367.367 0 110-.733h.366a.367.367 0 110 .733h-.366zm2.093 1.36a.386.386 0 01-.26.107.386.386 0 01-.26-.107.379.379 0 01-.107-.26c0-.1.04-.191.107-.26a.384.384 0 01.52 0c.066.069.107.165.107.26s-.04.19-.107.26zm0-13.72a.367.367 0 11-.52.52.367.367 0 01.52-.52zm-1.1 1.1a.367.367 0 11-.52.52.367.367 0 01.52-.52m0 2.2a.367.367 0 11-.52.52.367.367 0 01.52-.52m1.1-1.1a.367.367 0 11-.52.52.367.367 0 01.52-.52m1.1-1.1a.367.367 0 11-.52.52.367.367 0 01.52-.52m0 2.2a.367.367 0 11-.52.52.367.367 0 01.52-.52m-1.1 1.1a.367.367 0 11-.52.52.367.367 0 01.52-.52m0 2.2a.367.367 0 11-.52.52.367.367 0 01.52-.52" id="svgicon_box_a"/></defs><use xlink:href="#svgicon_box_a" transform="translate(-1 -1)"/>'
  }
})
