/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'key': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M20.55 3.351a.555.555 0 00-.384-.334c-.184-.05-.352.016-.485.15l-8.445 8.445-1.622-.535c-1.188-.401-2.893.017-3.78.886l-2.107 2.124c-.97.97-.97 2.542 0 3.512L8.41 22.28c.468.468 1.087.719 1.756.719.668 0 1.287-.25 1.755-.72l2.124-2.123c.903-.903 1.288-2.558.886-3.78l-.535-1.538 3.88-4.013c.1-.1.067-.385.067-.535v-.636h1.037c.284 0 .518-.183.518-.468v-.786l.702-.016c.134 0 .251-.05.352-.15.652-.653.852-1.54.551-2.409l-.953-2.475zm-9.983 16.004l-.385.384a1.136 1.136 0 01-.82.335c-.334 0-.685-.15-.936-.402l-2.123-2.14c-.502-.502-.519-1.271-.067-1.74l.384-.384c.435-.452 1.271-.418 1.74.067l2.14 2.14c.502.485.535 1.271.067 1.74z" id="svgicon_key_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_key_b" _fill="#fff"><use xlink:href="#svgicon_key_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_key_a"/><g mask="url(#svgicon_key_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
