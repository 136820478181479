import { get, post, update, remove } from '~/services/http-requests'

const _BS_LICENSE_OPTIONS = 'license_options'

/*
 **** GET ****
 */
function fetchLicenseOptionsLight(): Promise<ILicenseOptionLight[]> {
    return get({ baseProperty: _BS_LICENSE_OPTIONS, fullUrl: `${_BS_LICENSE_OPTIONS}.json?fields=id,name,contract_html` })
}

function fetchLicenseOptions(): Promise<ILicenseOptionProducer[]> {
    return get({ baseProperty: _BS_LICENSE_OPTIONS })
}

/*
 **** POST ****
 */
function createLicenseOption(licenseOption: ILicenseOptionProducer | Partial<ILicenseOptionProducer>): Promise<ILicenseOptionProducer> {
    return post({ baseProperty: _BS_LICENSE_OPTIONS, dispatchParams: licenseOption })
}

/*
 **** PATCH ****
 */
function updateLicenseOption(licenseOption: ILicenseOptionProducer | Partial<ILicenseOptionProducer>): Promise<ILicenseOptionProducer> {
    return update({
        baseProperty: _BS_LICENSE_OPTIONS,
        fullUrl: `${_BS_LICENSE_OPTIONS}/${licenseOption.id}/`,
        dispatchParams: licenseOption
    })
}

/*
 **** DELETE ****
 */
async function removeLicenceOption(licenseOptionId: ILicenseOptionProducer['id']) {
    return remove({ baseProperty: _BS_LICENSE_OPTIONS, fullUrl: `${_BS_LICENSE_OPTIONS}/${licenseOptionId}/` })
}

export { fetchLicenseOptionsLight, fetchLicenseOptions, createLicenseOption, updateLicenseOption, removeLicenceOption }
