/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M20.012 11.569L4.822 1.092A.523.523 0 104 1.523l-.001 20.953a.524.524 0 00.821.431l15.191-10.476a.523.523 0 000-.862z" id="svgicon_play_a"/></defs><use xlink:href="#svgicon_play_a"/>'
  }
})
