/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'warning': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M20.681 17.852c.507 1.041.517 2.053.026 2.84-.513.82-1.512 1.308-2.673 1.308H5.995c-1.143 0-2.089-.432-2.595-1.186-.358-.534-.659-1.479-.055-2.953L9.702 3.472C10.218 2.54 11.077 2 12.015 2c.937 0 1.797.54 2.298 1.445l6.368 14.407zm-8.666-1.203a1.004 1.004 0 100 2.007 1.004 1.004 0 000-2.007zm0-10.033c-.922 0-1.672.75-1.672 1.69l.67 7.37c.016.172.16.304.333.304h1.338a.335.335 0 00.333-.304l.66-7.221.01-.167c0-.921-.75-1.672-1.672-1.672z" id="svgicon_warning_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_warning_a"/>'
  }
})
