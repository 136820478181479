/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M18.411 14.458a.134.134 0 00-.039.118l.828 4.823c.125.73-.17 1.456-.77 1.89a1.929 1.929 0 01-2.037.149l-4.33-2.277a.134.134 0 00-.125 0l-4.33 2.276a1.922 1.922 0 01-2.038-.147 1.925 1.925 0 01-.77-1.891l.828-4.824a.134.134 0 00-.04-.118l-3.503-3.415a1.926 1.926 0 01-.49-1.983 1.924 1.924 0 011.562-1.316L8 7.04a.133.133 0 00.1-.073l2.166-4.388A1.925 1.925 0 0112 1.5c.74 0 1.408.415 1.735 1.078L15.9 6.967c.02.039.056.066.1.072l4.843.704a1.926 1.926 0 011.561 1.317c.229.704.04 1.466-.49 1.982l-3.503 3.416zm-1.257-1.29l3.503-3.415a.123.123 0 00.034-.137.122.122 0 00-.107-.091L15.74 8.82a1.931 1.931 0 01-1.456-1.058L12.12 3.375a.123.123 0 00-.12-.074.122.122 0 00-.12.074L9.715 7.763A1.932 1.932 0 018.258 8.82l-4.842.704a.123.123 0 00-.108.092.122.122 0 00.034.136l3.504 3.415c.456.444.664 1.085.557 1.712l-.827 4.822a.123.123 0 00.052.131.124.124 0 00.141.011l4.331-2.277a1.937 1.937 0 011.8 0l4.33 2.276c.05.027.097.023.142-.01a.123.123 0 00.053-.13l-.827-4.823c-.108-.627.1-1.267.556-1.712z" id="svgicon_star-o_a"/></defs><use _fill="#979797" fill-rule="nonzero" xlink:href="#svgicon_star-o_a"/>'
  }
})
