/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'followers': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M14.276 6.178a3.998 3.998 0 011.86 2.97 3.21 3.21 0 001.364.305 3.226 3.226 0 10-3.224-3.275zm-2.114 6.606a3.227 3.227 0 100-6.453 3.227 3.227 0 000 6.453zm1.369.22h-2.738a4.136 4.136 0 00-4.131 4.131v3.349l.008.052.231.072c2.174.68 4.063.906 5.617.906 3.036 0 4.796-.866 4.905-.92l.215-.11h.023v-3.349a4.135 4.135 0 00-4.13-4.131zm5.338-3.331h-2.717a3.976 3.976 0 01-1.227 2.77 4.909 4.909 0 013.506 4.697v1.032c2.683-.098 4.229-.858 4.33-.91l.216-.109H23v-3.349a4.136 4.136 0 00-4.13-4.131zm-12.368-.22c.63 0 1.218-.184 1.716-.498a3.993 3.993 0 011.5-2.546c.004-.06.01-.12.01-.182A3.226 3.226 0 106.5 9.453zm2.897 2.99a3.979 3.979 0 01-1.226-2.755c-.1-.007-.2-.015-.303-.015H5.13A4.136 4.136 0 001 13.804v3.349l.009.051.23.073c1.744.545 3.3.795 4.652.875V17.14a4.91 4.91 0 013.507-4.697z" id="svgicon_followers_a"/></defs><use xlink:href="#svgicon_followers_a"/>'
  }
})
