/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'home': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M21.841 11.364l-8.896-8.985c-.507-.504-1.391-.507-1.905.001l-8.944 9.034a.332.332 0 00.237.568h1v9.66c0 .184.149.333.332.333h5.33c.184 0 .333-.15.333-.333v-6.996h5.33v6.995c0 .184.15.333.333.333h5.33c.184 0 .333-.149.333-.333v-9.66h1.006a.333.333 0 00.181-.617z" id="svgicon_home_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_home_a"/>'
  }
})
