import Vue from 'vue'
import { namespace } from 'vuex-class'
import { VuexActionData } from '~/interfaces/interface-utils'
export const NUM_OF_ORDERS_PER_PAGE = 20

export const OrdersStore = namespace('orders')

interface State {
    sales: IOrderProducer[]
    orderPages: number
}

export default {
    namespaced: true,
    state: {
        sales: [],
        orderPages: null,
    } as State,
    mutations: {
        SET_ORDERS: (state: State, payload: IOrderProducer[]) => (state.sales = payload),
        SET_NUMBER_OF_PAGES: (state: State, payload: number) => (state.orderPages = payload),
    },
    actions: {
        async fetchProducerSales({ commit }: VuexActionData<State>, { limit, offset }: any) {
            commit('modal/SET_LOADING_STATE', true, { root: true })
            // set the url based on the params we have
            try {
                const { data } = await Vue.$axios.get(`/api_producer/sales.json/?limit=${limit}&offset=${offset}&fields=id,date,bought_beats_light`)
                if (offset === 0 && data.count) {
                    let numberOfPages = Math.trunc(data.count / NUM_OF_ORDERS_PER_PAGE)
                    // if the number isn't even, add 1 page to show the remaining beats
                    if (data.count > NUM_OF_ORDERS_PER_PAGE && data.count % NUM_OF_ORDERS_PER_PAGE !== 0) numberOfPages++
                    commit('SET_NUMBER_OF_PAGES', numberOfPages)
                }
                // if there is the key results we use it
                commit('SET_ORDERS', data.results ? data.results : data)
                commit('modal/SET_LOADING_STATE', false, { root: true })
            } catch (err) {
                commit('modal/SET_LOADING_STATE', false, { root: true })
            }
        }
    }
}
