import Sidebar from '#/layout/Sidebar.vue'
import Navbar from '#/layout/Navbar.vue'
import { castParam, LocaleComponent } from '~/services/router-helper'

export default [
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/',
        name: 'Home',
        redirect: { name: 'Dashboard' }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        components: {
            default: () => import('#/views/Dashboard.vue'),
            sidebar: Sidebar,
            navbar: Navbar
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/beats',
        components: { sidebar: Sidebar, navbar: Navbar, default: LocaleComponent },
        children: [
            {
                name: 'BeatsCatalog',
                path: 'catalog/:pageIndex',
                components: { default: () => import('#/views/BeatsCatalog.vue') },
                props: { default: castParam }
            },
            {
                name: 'BeatsReorder',
                path: 'reorder',
                components: { default: () => import('#/views/BeatsReorder.vue') }
            },
            {
                name: 'BeatsPrivateVault',
                path: 'private-vault',
                components: { default: () => import('#/views/BeatsPrivateVault.vue') }
            },
            {
                path: 'upload',
                name: 'BeatsUpload',
                components: { default: () => import('#/views/BeatsUpload.vue') }
            },
            {
                path: ':id',
                name: 'BeatEdit',
                components: { default: () => import('#/views/BeatEdit.vue') },
                props: { default: castParam }
            }
        ],
        meta: { requiresAuth: true }
    },
    {
        path: '/licenses',
        name: 'Licenses',
        components: { default: () => import('#/views/Licenses.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/coupons',
        name: 'Coupons',
        components: { default: () => import('#/views/Coupons.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/services',
        components: { sidebar: Sidebar, navbar: Navbar, default: LocaleComponent },
        children: [
            {
                path: '/',
                name: 'Services',
                components: { default: () => import('#/views/Services.vue') }
            },
            {
                path: 'new',
                name: 'ServiceNew',
                components: { default: () => import('#/views/Service.vue') }
            },
            {
                path: ':id',
                name: 'ServiceEdit',
                components: { default: () => import('#/views/Service.vue') },
                props: { default: castParam }
            }
        ]
    },
    {
        path: '/sound-kits',
        components: { sidebar: Sidebar, navbar: Navbar, default: LocaleComponent },
        children: [
            {
                path: '/',
                name: 'SoundKits',
                components: { default: () => import('#/views/SoundKits.vue') }
            },
            {
                path: 'new',
                name: 'SoundKitNew',
                components: { default: () => import('#/views/SoundKit.vue') }
            },
            {
                path: ':id',
                name: 'SoundKitEdit',
                components: { default: () => import('#/views/SoundKit.vue') },
                props: { default: castParam }
            }
        ]
    },
    {
        name: 'Orders',
        path: '/orders/:pageIndex',
        components: { default: () => import('#/views/Orders.vue'), sidebar: Sidebar, navbar: Navbar },
        props: { default: castParam },
        meta: { requiresAuth: true }
    },
    {
        path: '/orders/details/:id',
        name: 'OrderDetails',
        components: { default: () => import('#/views/OrderDetails.vue'), sidebar: Sidebar, navbar: Navbar },
        props: { default: castParam },
        meta: { requiresAuth: true }
    },
    {
        path: '/contracts',
        name: 'Contracts',
        components: { default: () => import('#/views/Contracts.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/settings',
        name: 'Settings',
        components: { default: () => import('#/views/SettingsGeneral.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/email-marketing',
        name: 'EmailMarketing',
        components: { default: () => import('#/views/EmailMarketing.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/preferences',
        name: 'SellingPreferences',
        components: { default: () => import('#/views/SettingsPayments.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/subscription',
        name: 'Subscription',
        components: { default: () => import('#/views/Subscription.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/amplify-player',
        name: 'AmplifyPlayer',
        components: { default: () => import('#/views/AmplifyPlayer.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/product',
        name: 'Product',
        components: { default: () => import('#/views/Product.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/elitepage',
        name: 'Elitepage',
        components: { default: () => import('#/views/Elitepage.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/promotion',
        name: 'Promotion',
        components: { default: () => import('#/views/Promotion.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/referral',
        name: 'Referral',
        components: { default: () => import('#/views/Referral.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/co-producers',
        name: 'CoProducers',
        components: { default: () => import('#/views/CoProducers.vue'), sidebar: Sidebar, navbar: Navbar },
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'Login',
        components: { default: () => import('#/views/Login.vue') },
        meta: { guest: true }
    }
]
