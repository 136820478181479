











import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import { ModalStore, IModalParams } from '~/store/modal'

@Component
export default class ConfirmActionModal extends Vue {
    @ModalStore.State('activeModal') activeModal: IModalParams

    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (params: IModalParams) => void

    callback() {
        this.activeModal.callback()
        // TODO: if you set to null you can't show "SUCCESS"
        // this.SET_ACTIVE_MODAL({ modal: null })
    }
}
