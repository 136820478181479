/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'elitepage': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M19.333 19.333c1.134 0 2.334 1.2 2.334 2.334 0 .2-.134.333-.334.333h-18c-.2 0-.333-.133-.333-.333 0-1.134 1.2-2.334 2.333-2.334h14zM5.667 12c-1.134 0-2-.867-2-2v-.133l2-4.334c.066-.133.2-.2.333-.2h12.667c.133 0 .266.067.333.2V5.6l2 4.267V10c0 1.133-.867 2-2 2-.667 0-1.333-.333-1.667-.933-.333.6-1 .933-1.666.933-.667 0-1.334-.333-1.667-.867-.333.534-1 .867-1.667.867-.666 0-1.333-.333-1.666-.867-.334.534-1 .867-1.667.867s-1.333-.333-1.667-.867c-.333.534-1 .867-1.666.867zm13-7.333H6c-.133 0-.2 0-.333.066V4c0-1.133.866-2 2-2H17c1.133 0 2 .867 2 2v.733c-.133-.066-.2-.066-.333-.066zm.333 14h-2V13c0-.2-.133-.333-.333-.333h-4c-.2 0-.334.133-.334.333v5.667H5.667v-6c.6 0 1.2-.2 1.666-.6.934.733 2.4.733 3.334 0 .933.733 2.4.733 3.333 0 .933.733 2.4.733 3.333 0 .467.4 1.067.6 1.667.6v6zm-6-5.334h3.333v5.334H13v-5.334z" id="svgicon_elitepage_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_elitepage_a"/>'
  }
})
